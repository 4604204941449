import { slug } from '@/src/modules/Common';
import Link from 'next/link';
import styles from './CardCinema.module.scss';
import { LANDING_URL } from '@/src/constants/global';

export default function CardCinema({
    title,
    backgroundColor,
    subscriptionId,
    style = {},
    hovered = false,
    styleHover = {},
    imageProps = {},
}) {
    const code = slug(title);
    const content = (
        <>
            <img
                className={styles['card-cinema__image']}
                src={`/assets/img/c-${code}.svg`}
                alt={code}
                style={style}
                {...imageProps}
            />
            <div className="card-cinema__hover" style={{ backgroundColor: backgroundColor, ...styleHover }}>
                <img
                    className="card-cinema__image"
                    src={`/assets/img/c-${code}${hovered ? '-hover' : ''}.svg`}
                    alt={code}
                    style={style}
                    {...imageProps}
                />
            </div>
        </>
    );

    if (subscriptionId) {
        return (
            <Link className="card-cinema" href={`/subscriptions/${code}-${subscriptionId}`} title={title}>
                {content}
            </Link>
        );
    }

    return (
        <Link
            className="card-cinema"
            title={title}
            href={LANDING_URL}
            rel={'nofollow noopener noreferrer'}
            target={'_blank'}
        >
            {content}
        </Link>
    );
}
