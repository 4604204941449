import styles from './Advantage.module.scss';
import SliderCinema from '@/components/slider-cinema/SliderCinema';
import BannerTop from '@/components/banner-top/BannerTop';
import Image from 'next/image';
import Button from '@/components/button/Button';
import { LANDING_URL } from '@/src/constants/global';
import Banner from '@/components/banner/Banner';
import { gtmEvent } from '@/src/modules/events/GTM';
import { useAuthStore } from '@/src/stores/RootStoreProvider';

export default function Advantage() {
    const onClick = () => {
        gtmEvent('banner', 'item_click', {
            title: 'Advantage',
        });
    };
    return (
        <div className={styles['advantage']}>
            <div className="advantage__row">
                <BannerTop
                    title={'Movix One: одна подписка для всего кино'}
                    subtitle={'В ней 5 онлайн-кинотеатров, ТВ и архив передач'}
                    action={
                        <Button
                            title={'Подключить и смотреть'}
                            className="button--primary button--large"
                            href={'/movix-one'}
                            type="link"
                            onClick={onClick}
                        />
                    }
                    image={
                        <Image
                            src={'/assets/img/banner-movix-one.webp'}
                            width={754}
                            height={503}
                            priority={true}
                            alt=""
                        />
                    }
                />
            </div>
            <div className="advantage__row">
                <SliderCinema />
            </div>
        </div>
    );
}

export function AdvantageTelevision() {
    const { isME } = useAuthStore();
    const onClick = () => {
        gtmEvent('banner', 'item_click', {
            title: 'AdvantageTelevision',
        });
    };
    return (
        <div className="advantage">
            <div className="advantage__row">
                <Banner
                    title={'Включайте когда удобно и управляйте эфиром'}
                    subtitle={'ТВ-каналы и телеархив уже включены в подписку Movix One'}
                    action={
                        <Button
                            title={isME ? 'Попробуйте 7 дней' : 'Подключить и смотреть'}
                            className="button--primary button--large"
                            href={'/television'}
                            type="link"
                            onClick={onClick}
                        />
                    }
                    image={<Image src={'/assets/img/banner-television.webp'} width={673} height={336} alt="" />}
                />
            </div>
        </div>
    );
}

export function AdvantageTvBox() {
    const onClick = () => {
        gtmEvent('banner', 'item_click', {
            title: 'AdvantageTvBox',
        });
    };
    return (
        <div className="advantage">
            <div className="advantage__row">
                <Banner
                    title={'Еще больше контента с приставкой Movix'}
                    subtitle={'Новые фильмы, сериалы, спортивные трансляции и видео на VK и RUTUBE'}
                    action={
                        <Button
                            title="Купить приставку"
                            className="button--pro button--label"
                            label={'subtract'}
                            href={LANDING_URL}
                            target="_blank"
                            rel="noreferrer"
                            type="link"
                            onClick={onClick}
                        />
                    }
                    image={<Image src={'/assets/img/banner-tvbox.webp'} width={460} height={336} alt="" />}
                />
            </div>
        </div>
    );
}
