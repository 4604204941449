import styles from './BannerTop.module.scss';

export default function BannerTop({ title, subtitle, action, image }) {
    return (
        <div className={styles['banner-top']}>
            <div className="banner-top__content">
                <div className="banner-top__title">{title}</div>
                {subtitle && <div className="banner-top__subtitle">{subtitle}</div>}
                {action && <div className="banner-top__action">{action}</div>}
            </div>
            <div className={styles['banner-top__image']}>{image}</div>
        </div>
    );
}
