import styles from './Promo.module.scss';
import assetsPic from './img/banner.png';
import Image from 'next/image';

export default function Promo({ modifier = '', title, text, children, imgAlt }) {
    return (
        <div className={styles['b-promo'] + (modifier ? ' ' + modifier : '')}>
            <div className={styles['b-promo__content']}>
                <div className={styles['b-promo__title']}>{title}</div>
                {text && <div className="b-promo__text">{text}</div>}
                {children}
            </div>
            <div className={styles['b-promo__image']}>
                <Image className="b-promo__image-img" src={assetsPic} alt={imgAlt} width={712} height={496} />
            </div>
        </div>
    );
}
