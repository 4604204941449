import Page from '@/components/v3/Page';
import PageModel from '@/src/api/v3/models/Page';
import React from 'react';
import Showcase, { RenderType } from '@/src/api/v3/models/Showcase';
import Button from '@/components/button/Button';
import Promo from '@/components/promo/Promo';
import Advantage, { AdvantageTelevision, AdvantageTvBox } from '@/components/advantage/Advantage';
import { processError } from '@/src/helpers/ErrorHelper';
import { getApi } from '@/src/helpers/ApiHelper';
import ApiToken from '@/src/models/ApiToken';

// noinspection JSUnusedGlobalSymbols
export default function Index({ data }) {
    return <MemoPageData data={data} />;
}

Index.getInitialProps = async (context) => {
    const api = await getApi(context);
    const isGuest = ApiToken.isGuest(api.token);
    const limit = isGuest && typeof window === 'undefined' ? 1 : 1;
    try {
        const data = await api.pages.loadPageData({ urn: 'pages/library', limit: limit });
        return {
            data,
        };
    } catch (error) {
        processError(error, context, api);
    }
};

function PageData({ data }) {
    const page = new PageModel(data);

    const showcaseAdvantage = new Showcase({
        total: 1,
        id: 'html-advantage',
    });
    showcaseAdvantage.html = <Advantage />;
    showcaseAdvantage.renderType = RenderType.HTML;
    page.showcases.splice(0, 0, showcaseAdvantage);

    const footerContent = (
        <>
            <AdvantageTelevision />
            <AdvantageTvBox />
            <FooterContent />
        </>
    );

    page.pageTitle = 'Онлайн-кинотеатр Movix - фильмы, сериалы и мультфильмы смотреть онлайн в хорошем качестве';
    page.pageDescription =
        'Устройте онлайн-кинотеатр у себя дома вместе с Movix! Смотрите фильмы, сериалы, мультфильмы и ТВ-каналы в высоком качестве. Наслаждайтесь просмотром в удобное для вас время и на любых устройствах одновременно.';
    return <Page page={page} containerTopContent={<TopContent />} containerFooterContent={footerContent} />;
}

function TopContent() {
    return <h1 className="h-hidden">Онлайн-кинотеатр Movix</h1>;
}

function FooterContent() {
    return (
        <>
            <Promo
                modifier={'b-promo--main'}
                title={<>Смотрите любимые фильмы и&nbsp;сериалы на&nbsp;любых устройствах</>}
                imgAlt={'Смотрите любимые фильмы и&nbsp;сериалы на&nbsp;любых устройствах'}
            >
                <Button
                    type={'link'}
                    href={'/promo'}
                    className={'button--large b-promo__btn'}
                    title={'Узнать больше'}
                />
            </Promo>
        </>
    );
}

const MemoPageData = React.memo(PageData);
