import { SwiperSlide } from 'swiper/react';
import CardCinema from '@/components/card-cinema/CardCinema';
import { Slider } from '@/components/v3/ShowcaseSlider';
import Showcase from '@/src/api/v3/models/Showcase';
import styles from './SliderCinema.module.scss';
import { useState } from 'react';
import { Subscriptions } from '@/src/constants/subscriptions';

export default function SliderCinema() {
    const [isVisible, setIsVisible] = useState(false);

    const showcase = new Showcase({
        total: 6,
        id: 'html-cinema',
    });
    showcase.items = [
        {
            title: 'AMEDIATEKA',
            backgroundColor: '#0D0D0D',
            subscriptionId: 251360,
            imageProps: { width: 226, height: 21 },
        },
        {
            title: 'Viju',
            backgroundColor: '#03C1AD',
            subscriptionId: 566829,
            hovered: true,
            imageProps: { width: 78, height: 52 },
        },
        {
            title: 'Кинопоиск',
            backgroundColor: '#151515',
            subscriptionId: Subscriptions.KINOPOISK,
            style: {
                top: '8px',
            },
            styleHover: {
                backgroundImage: 'url(/assets/img/c-kinopoisk-hover.svg)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            },
            imageProps: { width: 146, height: 41 },
        },
        {
            title: 'Иви',
            backgroundColor: '#100E18',
            subscriptionId: Subscriptions.IVI,
            imageProps: { width: 96, height: 49 },
        },
        {
            title: 'Premier',
            backgroundColor: '#000000',
            subscriptionId: 4262908,
            imageProps: { width: 170, height: 22 },
        },
        {
            title: 'Start',
            backgroundColor: '#B51E2F',
            subscriptionId: 1340000,
            hovered: true,
            imageProps: { width: 78, height: 38 },
        },
    ];

    return (
        <Slider
            showcase={showcase}
            itemClass={''}
            onSwiperInit={() => {
                setIsVisible(true);
            }}
            slideClassName={styles['slider-cinema__item']}
            sliderClassName={'slider-cinema' + (isVisible ? ' slider-cinema--visible' : '')}
            cards={showcase.items.map((item) => (
                <SwiperSlide className={'slider-cinema__item'} key={item.title}>
                    <CardCinema {...item} />
                </SwiperSlide>
            ))}
            breakpoints={{
                1023: {
                    slidesPerView: 6,
                    slidesPerGroup: 6,
                },
                0: {
                    slidesPerView: 'auto',
                    slidesPerGroup: 1,
                },
            }}
        />
    );
}
