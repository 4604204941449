import styles from './Banner.module.scss';

export default function Banner({ title, subtitle, action, image }) {
    return (
        <div className={styles['banner']}>
            <div className="banner__content">
                <div className="banner__title">{title}</div>
                {subtitle && <div className="banner__subtitle">{subtitle}</div>}
                {action && <div className="banner__action">{action}</div>}
            </div>
            <div className={styles['banner__image']}>{image}</div>
        </div>
    );
}
