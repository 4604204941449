import { COOKIES } from '@/src/constants/cookies';
import ApiBackendException from '@/src/api/ApiBackendException';

const maxAgeCookie = 365 * 24 * 3600;

export function processError(error, context, api) {
    const { res } = context;
    if (res && error instanceof ApiBackendException) {
        if (error.message === 'token expired' && api.tokenUpdated) {
            res.writeHead(302, {
                Location: context.asPath,
                'Set-Cookie': `${COOKIES.API_TOKEN}=${api.token}; Max-Age: ${maxAgeCookie}`,
            });
            res.end();
        }
    }
}
